import CtaText from '@/components/CtaText';
import CtaLink from '@/components/CtaLink';
import RightCheveron from '@/components/Icons/RightCheveron';

export default function OnboardingHeader({
  title,
  subCopy,
  ctaCopy,
  ctaSrc,
}: {
  title: string;
  subCopy: string;
  ctaCopy: string;
  ctaSrc: string;
}) {
  return (
    <div className="col-span-12">
      <h2 className="copy-xl mb-1.6 text-center">{title}</h2>
      <div className="flex items-center justify-center">
        <p className="text-black text-opacity-50 text-body w-max mr-1.6">
          {subCopy}
        </p>
        <CtaLink
          color="text-black"
          height="h-auto"
          width="w-max"
          background="transparent"
          href={ctaSrc}
        >
          <CtaText font="text-body" textTransform="">
            {ctaCopy}
          </CtaText>
          <RightCheveron />
        </CtaLink>
      </div>
    </div>
  );
}
