import CtaLink from '@/components/CtaLink';
import CtaText from '@/components/CtaText';
import RightCheveron from '@/components/Icons/RightCheveron';
import ImageMarqueeCarousel from '@/components/ImageMarqueeCarousel';
import OnboardingHeader from '@/components/OnboardingHeader';
import PageLayout from '@/components/PageLayout';
import { NavBarLightTheme } from '@/components/PageLayout/PageNavBar';
import TwelveColGrid from '@/components/TwelveColGrid';
import {
  CLIENT_FORGOT_PASSWORD_URL,
  CLIENT_LOGIN_VIA_EMAIL_URL,
  CLIENT_SIGNUP_VIA_EMAIL_URL,
  defaultImageMarqueeCarouselImages,
} from '@/utils/constants';
import { genQs } from '@/utils/v2HelperMethods';
import { GetServerSideProps } from 'next';
import Link from 'next/link';

export const getServerSideProps: GetServerSideProps = async ({ query }) => {
  const { redirect = null } = query;
  return { props: { redirect } };
};

export default function LoginSplashPage({
  redirect,
}: {
  redirect: string | null;
}) {
  const params = redirect ? genQs({ redirect }) : '';
  return (
    <PageLayout>
      <NavBarLightTheme sticky={false} />
      <TwelveColGrid my="mt-6.4 mb-8">
        <OnboardingHeader
          title="WELCOME BACK"
          subCopy="Haven't joined yet?"
          ctaCopy="Join now"
          ctaSrc={CLIENT_SIGNUP_VIA_EMAIL_URL + params}
        />
      </TwelveColGrid>
      <div className="relative flex flex-col items-center">
        <ImageMarqueeCarousel imgUrlArray={defaultImageMarqueeCarouselImages} />
        <div className="pb-1.6 border-b border-black border-opacity-10 mb-10">
          <CtaLink
            width="w-34.4 lgt:w-36.0"
            height="h-4.8"
            href={CLIENT_LOGIN_VIA_EMAIL_URL + params}
          >
            <CtaText>LOGIN WITH EMAIL</CtaText>
            <RightCheveron />
          </CtaLink>
        </div>
        <Link
          href={CLIENT_FORGOT_PASSWORD_URL}
          className="text-xs text-black-300"
        >
          FORGOT PASSWORD?
        </Link>
      </div>
    </PageLayout>
  );
}
