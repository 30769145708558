import s from './ImageMarqueeCarousel.module.css';
import Image from "next/legacy/image";
export default function ImageMarqueeCarousel({
  imgUrlArray,
}: {
  imgUrlArray: string[];
}) {
  return (
    <div className={s.marquee_wrapper}>
      <div className={s.marquee}>
        {imgUrlArray.map((v, i) => {
          return (
            <div key={i} className={s.img_wrapper}>
              <Image src={v} alt="" layout="fill" objectFit="contain" />
            </div>
          );
        })}
      </div>
    </div>
  );
}
